import React from 'react'
import {
  Card, CardHeader, CardBody, CardFooter, CustomInput,
  FormGroup, Input, Label,
  Modal, ModalBody, ModalFooter,
  Spinner, NavLink
} from 'reactstrap'
import { Link } from 'react-router-dom'

import { AppContext, } from 'contexts/AppContext'
import { useFormatMessage } from 'hooks/intl.hooks'

import CustomSelect from '../../components/CustomSelect'
import { T, TAlert, Toption, TButton, TLabel, TCustomInput, TInput } from 'components/TComponents'
import Button from 'components/Button'

import ItvcodeSelector from 'components/ItvcodeSelector'

import { debounce } from 'util/Utils'

const MaintenanceStart = ({ match, history }) => {
  const { api, subsidiary, user, CHECKLIST_STATUS } = React.useContext(AppContext)
  const formatMessage = useFormatMessage()

  const [maintenance, setMaintenance] = React.useState()
  const [maintenanceLoading, setMaintenanceLoading] = React.useState(true)
  const [maintenanceError, setMaintenanceError] = React.useState()
  const [baseMaintenance, setbaseMaintenance] = React.useState()

  const [itvcodesBase, setItvcodesBase] = React.useState([])
  const [itvcodesLoading, setItvcodesLoading] = React.useState(true)
  const [itvcodesError, setItvcodesError] = React.useState()

  const [isUserAssigned, setIsUserAssigned] = React.useState()

  const [notAllowedError, setNotAllowedError] = React.useState()
  const [finishError, setFinishError] = React.useState()

  const [changeUserModal, setChangeUserModal] = React.useState(false)
  const [forceUserAssign, setForceUserAssign] = React.useState(false)

  const [equipments, setEquipments] = React.useState()
  const [equipmentsFiltered, setEquipmentsFiltered] = React.useState([])
  const [equipmentsFilteredByUser, setEquipmentsFilteredByUser] = React.useState([])
  const [equipmentsError, setEquipmentsError] = React.useState()
  const [equipmentsFilteredByUserLoading, setEquipmentsFilteredByUserLoading] = React.useState(false)

  const [freshEquipment, setFreshEquipment] = React.useState(0)

  const [currentEquipment, setCurrentEquipment] = React.useState()
  const [equipmentItvcodes, setEquipmentItvcodes] = React.useState()
  const [equipmentItvcodesError, setEquipmentItvcodesError] = React.useState()
  const [availableStatus, setAvailableStatus] = React.useState([])
  const [availableStatusError, setAvailableStatusError] = React.useState()
  const [statusIdTo, setStatusIdTo] = React.useState()
  const [childStatusIdTo, setChildStatusIdTo] = React.useState()
  const [equipmentChecklist, setEquipmentChecklist] = React.useState()
  const [equipmentChecklistError, setEquipmentChecklistError] = React.useState()
  const [toChecklistError, setToChecklistError] = React.useState()
  const [nextEquipmentError, setNextEquipmentError] = React.useState()

  const [techcenterBase, setTechcenterBase] = React.useState()
  const [techcenterError, setTechcenterError] = React.useState()
  const [techcenterLoading, setTechcenterLoading] = React.useState()

  const [equipmentNumber, setEquipmentNumber] = React.useState()
  const [doNotDisplayEquipment, setDoNotDisplayEquipment] = React.useState(false)

  const [step, setStep] = React.useState(1)

  const [searchInput, setSearchInput] = React.useState()

  const {
    maintenanceplanId,

    businessName,
    categoryName,
    familyName,
    addressLocationLabel,

    statusValue,
    businessId, itvcodes,

    firstName,
    lastName,
    userIdAssignedTo,

    datePlanned,
    durationEstimated,
    timeRangeStart,
    timeRangeEnd,
    information,
    addressFull,
    masterplanId

  } = maintenance || {}

  React.useEffect(() => {
    api.get('/maintenanceplans/details', undefined, { maintenanceplanId: match.params.id })
      .then(response => {
        setMaintenance(response)
        setbaseMaintenance({ ...response })
        response.itvcodes.map((codes, i) => {
          return api.get('/itvcodes/list', undefined, {
            ipp: -1,
            businessId: response.businessId,
            familyId: codes.familyId,
            categoryId: codes.categoryId
          })
            .then(response => {
              setItvcodesBase(itvcodesBase => ({
                ...itvcodesBase,
                [i]: response
              }))
            })
            .catch(response => setItvcodesError(response))
        })
      })
      .catch(response => setMaintenanceError(response))
      .then(() => {
        setItvcodesLoading(false)
        setMaintenanceLoading(false)
      })
  }, [api, match])

  React.useEffect(() => {
    if (baseMaintenance && (baseMaintenance.statusValue !== "scheduled" && baseMaintenance.statusValue !== "new")) {
      setNotAllowedError({ code: 'notAllowed' })
    }
    if (baseMaintenance && user) {
      setIsUserAssigned(baseMaintenance.userIdAssignedTo === user.userId)
    }
  }, [baseMaintenance, user])

  React.useEffect(() => {
    if (maintenance && !equipments) {
      setEquipmentsFilteredByUserLoading(true)
      api.get('/maintenanceplans/equipments', undefined, {
        maintenanceplanId,
        freshEquipment
      })
        .then(response => {
          setEquipments(response.equipments)
          setEquipmentsFilteredByUser(response.equipments)
        })
        .catch(error => setEquipmentsError(error))
        .then(() => setEquipmentsFilteredByUserLoading(false))
    }
  }, [api, maintenanceplanId, equipments, maintenance, freshEquipment])

  React.useEffect(() => {
    if (baseMaintenance && subsidiary && subsidiary.companyId) {
      api.get('/techcenters/list', undefined, { companyId: subsidiary.companyId, ipp: -1 })
        .then(response => {
          setTechcenterBase(response.result)
        })
        .catch(error => {
          setTechcenterError(error)
        })
        .then(() => setTechcenterLoading(false))
      api.get('/companies/details', undefined, { companyId: subsidiary.companyId})
        .then(response => setMaintenance(maintenance => ({
          ...maintenance,
          partsOriginId: response.techcenterId
        })))
        .catch(error => setTechcenterError(error))
        .then(() => setTechcenterLoading(false))
    } else {
      api.get('/techcenters/list', undefined, { ipp: -1 })
        .then(response => {
          setTechcenterBase(response.result)
        })
        .catch(error => {
          setTechcenterError(error)
        })
        .then(() => setTechcenterLoading(false))
    }
  }, [api, subsidiary, baseMaintenance])

  const getEquipmentStatus = React.useCallback(() => {
    if (maintenance) {
      api.get('/maintenanceplans/equipmentsStatus', undefined, {
        maintenanceplanId,
        equipmentId: equipmentsFiltered[currentEquipment].equipmentId
      })
        .then(response => {
          setAvailableStatus(response && response.statusIdsTo)
          setStatusIdTo(response && response.statusIdToSelected)
          setChildStatusIdTo(response && response.childStatusIdToSelected)
        })
        .catch(error => setAvailableStatusError(error))
    }
  }, [maintenance, api, maintenanceplanId, equipmentsFiltered, currentEquipment])

  const getEquipmentItvcodesAndChecklist = React.useCallback(() => {
    if (maintenance) {
      api.get('/maintenanceplans/checklist', undefined, {
        maintenanceplanId,
        equipmentId: equipmentsFiltered[currentEquipment].equipmentId
      })
        .then(response => {
          setEquipmentChecklist({
            ...response,
            checklist: response.checklist.map(c => {
              c.status = c.status || 1
              return c
            })
          })
        })
        .catch(error => setEquipmentChecklistError(error))
      api.get('/maintenanceplans/itvcodes', undefined, {
        maintenanceplanId,
        equipmentId: equipmentsFiltered[currentEquipment].equipmentId
      })
        .then(response => setEquipmentItvcodes(response))
        .catch(error => setEquipmentItvcodesError(error))
    }
  }, [maintenance, api, maintenanceplanId, equipmentsFiltered, currentEquipment])

  const handleChangePartsOriginId = React.useCallback(techcenter => {
    setMaintenance(maintenance => ({
      ...maintenance,
      ...{ partsOriginId: techcenter.techcenterId },
    }))
  }, [])

  const handleEquipmentClick = React.useCallback(item => {
    setEquipments(equipments.map(equipmentFamily => ({
      ...equipmentFamily,
      equipments: equipmentFamily.equipments.map(equipment => ({
        ...equipment,
        enable: equipment.equipmentId === item.equipmentId ? !equipment.enable : equipment.enable
      }))
    })))
    setEquipmentsFilteredByUser(equipmentsFilteredByUser.map(equipmentFamily => ({
      ...equipmentFamily,
      equipments: equipmentFamily.equipments.map(equipment => ({
        ...equipment,
        enable: equipment.equipmentId === item.equipmentId ? !equipment.enable : equipment.enable
      }))
    })))
  }, [equipments, equipmentsFilteredByUser])

  React.useEffect(() => {
    if (equipments && equipments.length > 0) {
      setEquipmentsFiltered([].concat(...equipments.map(equipmentFamily => (
        equipmentFamily.equipments.map(equipment => ({
          ...equipment,
          enable: equipment.enable ? 1 : 0,
          familyId: equipmentFamily.familyId,
          categoryId: equipmentFamily.categoryId,
          categoryName: equipmentFamily.categoryName,
          familyName: equipmentFamily.familyName,
        }))
      ))).filter(e => e.enable))
    }
  }, [equipments])

  React.useEffect(() => {
    if (currentEquipment || currentEquipment === 0) {
      getEquipmentItvcodesAndChecklist()
      getEquipmentStatus()
    }
  }, [getEquipmentItvcodesAndChecklist, getEquipmentStatus, currentEquipment])

  const toEquipment = React.useCallback(() => {
    const equipmentsToPost = [].concat(...equipments.map(equipmentFamily => (
      equipmentFamily.equipments.map(equipment => ({
        ...equipment,
        enable: equipment.enable ? 1 : 0
      }))
    )))
    const equipmentsEnable = equipmentsToPost.filter(e => e.enable)
    if ((equipmentsEnable && equipmentsEnable.length > 0) && maintenance && maintenance.partsOriginId) {
      api.post('/maintenanceplans/equipments', {
        _stringifyBody: true,
        body: {
          maintenanceplanId: maintenance.maintenanceplanId,
          partsOriginId: maintenance.partsOriginId,
          equipments: equipmentsToPost
        }
      })
        .then(response => {
          if (response.code) {
            setEquipmentsError(response.code)
          } else {
            setEquipmentsError()
            setStep(2)
            setCurrentEquipment(0)
          }
        })
        .catch(error => setEquipmentsError(error))
    } else {
      if (!maintenance || (maintenance && !maintenance.partsOriginId)) {
        setEquipmentsError({ code: 'partsOriginError' })
      } else if (!equipmentsEnable || (equipmentsEnable && equipmentsEnable.length === 0)) {
        setEquipmentsError({ code: 'equipmentsError' })
      }
    }
  }, [api, equipments, maintenance])

  const toNextEquipment = React.useCallback(() => {
    if (!statusIdTo) {
      setNextEquipmentError({ code: 'statusToIsRequired' })
    } else {
      api.post('/maintenanceplans/itvcodes', {
        _stringifyBody: true,
        body: {
          ...equipmentItvcodes,
          techComments: equipmentItvcodes.techComments || ''
        }
      }).then(() => {
        api.post('/maintenanceplans/checklist', {
          _stringifyBody: true,
          body: {
            ...equipmentChecklist,
            status: equipmentChecklist.status || 3
          }
        }).then(() => {
          api.post('/maintenanceplans/equipmentStatus', {
            _stringifyBody: true,
            body: {
              equipmentId: equipmentsFiltered[currentEquipment].equipmentId,
              maintenanceplanId,
              statusIdTo: statusIdTo,
              childStatusIdTo: childStatusIdTo
            }
          }).then(() => {
            setEquipmentItvcodes({ equipmentId: equipmentItvcodes.equipmentId })
            setEquipmentChecklist()
            setStatusIdTo(0)
            setChildStatusIdTo()
            setCurrentEquipment(currentEquipment + 1)
            setNextEquipmentError()
          }).catch(error => {
            setNextEquipmentError(error)
          })
        }).catch(error => {
          setNextEquipmentError(error)
        })
      }).catch(error => {
        setNextEquipmentError(error.code)
      })
    }
  }, [api, equipmentItvcodes, equipmentChecklist, equipmentsFiltered, currentEquipment, maintenanceplanId, statusIdTo, childStatusIdTo])

  const toPreviousEquipment = React.useCallback(() => {
    setCurrentEquipment(currentEquipment - 1)
    setEquipmentChecklistError()
    setNextEquipmentError()
  }, [currentEquipment])

  React.useEffect(() => {
    if (step === 2 && equipmentItvcodes && equipmentNumber !== undefined) {
      postItvcodes(equipmentNumber)
    }
  }, [step, equipmentNumber, postItvcodes, equipmentItvcodes])

  const postItvcodes = React.useCallback((equipmentNumber) => {
    if (equipmentItvcodes) {
      api.post('/maintenanceplans/itvcodes', {
        _stringifyBody: true,
        body: {
          ...equipmentItvcodes,
          techComments: equipmentItvcodes.techComments || ''
        }
      })
        .then(response => {
          if (response.code) {
            setToChecklistError(response)
          } else {
            if (equipmentNumber + 1 === equipmentsFiltered.length) {
              setCurrentEquipment(0)
              setEquipmentItvcodes()
              setToChecklistError()
              setEquipmentNumber()
              setDoNotDisplayEquipment(false)
            } else {
              setEquipmentItvcodes({ equipmentId: equipmentItvcodes.equipmentId })
              setEquipmentNumber(equipmentNumber + 1)
              setDoNotDisplayEquipment(true)
            }
          }
        })
        .catch(error => {
          setToChecklistError(error)
        })
    }
  }, [api, equipmentItvcodes, equipmentsFiltered])

  const handleCheckChange = React.useCallback(({ item, status, comments }) => {
    if (status) {
      setEquipmentChecklist({
        ...equipmentChecklist,
        checklist: equipmentChecklist.checklist.map(checklistItem => ({
          ...checklistItem,
          status: item.priority === checklistItem.priority ? status : (checklistItem.status ? checklistItem.status : 1)
        }))
      })
    }
    if (comments || comments === "") {
      setEquipmentChecklist({
        ...equipmentChecklist,
        checklist: equipmentChecklist.checklist.map(checklistItem => ({
          ...checklistItem,
          comments: item.priority === checklistItem.priority ? comments : (checklistItem.comments ? checklistItem.comments : '')
        }))
      })
    }
  }, [equipmentChecklist])

  const handleCheckAllOk = React.useCallback(() => {
    setEquipmentChecklist({
      ...equipmentChecklist,
      checklist: equipmentChecklist.checklist.map(checklistItem => ({
        ...checklistItem,
        status: 3
      }))
    })
  }, [setEquipmentChecklist, equipmentChecklist])

  const handleChangeStatusTo = React.useCallback(status => {
    if (status) {
      const statusValue = status.toString().split('.')
      setStatusIdTo(statusValue[0])
      setChildStatusIdTo(statusValue[1])
    }
  }, [])

  const handleFinish = React.useCallback(() => {
    if (!statusIdTo) {
      setNextEquipmentError({ code: 'statusToIsRequired' })
    } else {
      api.post('/maintenanceplans/itvcodes', {
        _stringifyBody: true,
        body: {
          ...equipmentItvcodes,
          techComments: equipmentItvcodes.techComments || ''
        }
      })
        .then(() => {
          api.post('/maintenanceplans/checklist', {
            _stringifyBody: true,
            body: {
              ...equipmentChecklist,
              status: equipmentChecklist.status || 3
            }
          })
            .then(() => {
              api.post('/maintenanceplans/equipmentStatus', {
                _stringifyBody: true,
                body: {
                  equipmentId: equipmentsFiltered[currentEquipment].equipmentId,
                  maintenanceplanId,
                  statusIdTo: statusIdTo,
                  childStatusIdTo: childStatusIdTo
                }
              })
                .then(() => {
                  setEquipmentChecklist()
                  setStatusIdTo(0)
                  setChildStatusIdTo()
                  setEquipmentChecklistError()
                  api.post('/maintenanceplans/end', {
                    _stringifyBody: true,
                    body: {
                      maintenanceplanId: maintenanceplanId,
                      partsOriginId: maintenance.partsOriginId,
                    }
                  })
                    .then(() => history.push(`/maintenance/${maintenanceplanId}`))
                    .catch(error => setFinishError(error))
                })
                .catch(error => {
                  setNextEquipmentError(error)
                })
            })
            .catch(error => {
              setNextEquipmentError(error)
            })
        })
        .catch(error => {
          setNextEquipmentError(error)
        })
    }
  }, [statusIdTo, api, equipmentItvcodes, equipmentChecklist, equipmentsFiltered, currentEquipment, maintenanceplanId, childStatusIdTo, maintenance, history])

  const refresh = React.useCallback(params => {
    if (maintenance) {
      setEquipmentsFilteredByUserLoading(true)
      api.get('/maintenanceplans/equipments', undefined, {
        maintenanceplanId,
        freshEquipment,
        ...params
      })
        .then(response => {
          setEquipmentsFilteredByUser(response.equipments.map(equipmentFamily => ({
            ...equipmentFamily,
            equipments: equipmentFamily.equipments.map(equipment => ({
              ...equipment,
              enable: !!(equipments.find(eqF => eqF.familyId === equipmentFamily.familyId) && equipments.find(eqF => eqF.familyId === equipmentFamily.familyId).equipments.find(eq => eq.equipmentId === equipment.equipmentId).enable)
            }))
          })))
        })
        .catch(error => setEquipmentsError(error))
        .then(() => setEquipmentsFilteredByUserLoading(false))
    }
  }, [api, equipments, maintenance, maintenanceplanId, freshEquipment])

  const refreshDebounced = React.useMemo(() => debounce(params => refresh(params), 250), [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setSearchInput(value)
    refreshDebounced({ equipmentIdentifier: value, freshEquipment })
  }, [refreshDebounced, freshEquipment])

  const handleFilterFreshEquipments = React.useCallback(() => {
    setFreshEquipment(freshEquipment === 0 ? 1 : 0)
    refreshDebounced({ freshEquipment: freshEquipment === 0 ? 1 : 0, equipmentIdentifier: searchInput })
  }, [freshEquipment, refreshDebounced, searchInput])

  const renderStep1 = React.useCallback(() => {
    return (
      <CardBody>
        {!isUserAssigned && !hasStatus('new') && (
          <TAlert id="notUserAssigned" color="warning" />
        )}

        <FormGroup tag="fieldset">
          <TLabel className="mb-0" id="statusValue" />
          <T id="interventionStatus.started" className="pl-1" />
        </FormGroup>

        {firstName && lastName && (
          <FormGroup tag="fieldset">
            <TLabel className="mb-0" id="userAssign" />
            {maintenanceLoading
              ? <Spinner className="ml-2" color="primary" size="sm" />
              :
              <>
                {forceUserAssign ? (
                  <span>{`${user.firstName} ${user.lastName}`}</span>
                ) : (
                  <span>{`${firstName} ${lastName}`}</span>
                )}
              </>
            }
            {userIdAssignedTo !== user && !forceUserAssign && (
              <TButton className="ml-4" id="assignToMe" onClick={() => setChangeUserModal(true)} />
            )}
          </FormGroup>
        )}

        <FormGroup tag="fieldset">
          <TLabel className="mb-0" id="datePlanned.label" />
          <T id="datePlanned.value"
            values={{
              datePlanned: datePlanned ? new Date(datePlanned) : null,
              timeStart: timeRangeStart ? new Date().setHours(0, Math.min(timeRangeStart), 0, 0) : undefined,
              timeEnd: timeRangeStart ? new Date().setHours(0, Math.max(timeRangeEnd), 0, 0) : undefined
            }}
            className="pl-1" />
        </FormGroup>

        <FormGroup tag="fieldset">
          <TLabel className="mb-0"  id="location" />
          {addressLocationLabel ? (
            <span>{addressLocationLabel}</span>
          ) : (
            <T id="noLocation" />
          )}
        </FormGroup>

        <FormGroup tag="fieldset">
          <TLabel id="durations" />
          {/* Display /60 hour */}
          <TLabel
            id="durationEstimated"
            values={{
              hours: Math.floor(durationEstimated / 60),
              minutes: durationEstimated % 60,
            }}
            className="col mb-0" />
        </FormGroup>

        <FormGroup tag="fieldset">
          <TLabel id="information" />
          {information ? (
            <span>{information}</span>
          ) : (
            <T id="noInformation" />
          )}
        </FormGroup>

        <TLabel id="addressTitle" />
        <div>
          <p>
            {addressFull && addressFull}
            {!addressFull && <T id="addressNotFound" />}
          </p>
        </div>

        {techcenterBase && maintenance && <FormGroup tag="fieldset" className="my-4">
          <TLabel id="partsOriginId" />
          <CustomSelect
            inputId="partsOriginId"
            name="partsOriginId"
            onChange={e => handleChangePartsOriginId(e)}
            isDisabled={loading}
            options={techcenterBase}
            getOptionLabel={option => option.techcenterName}
            getOptionValue={option => option.techcenterId}
            value={techcenterBase.filter(t => t.techcenterId === maintenance.partsOriginId)} />
        </FormGroup>}

        <TLabel id="equipmentChoice" />
        <TAlert id="equipmentChoiceAlert" color="warning" />
        {masterplanId && (
          <TButton className="mb-2"
            disabled={loading}
            id={freshEquipment ? `showAllEquipments` : `showFreshEquipments`}
            onClick={() => { handleFilterFreshEquipments() }}
            color="primary" />
        )}

        <FormGroup tag="fieldset">
          <TInput
            id="filter"
            name="filter"
            type="text"
            value={searchInput}
            onChange={handleSearchInput}
            placeholder="searchInput" />
        </FormGroup>
        {!equipmentsFilteredByUserLoading ? (
          <>
            {equipmentsFilteredByUser && equipmentsFilteredByUser.length > 0 ? equipmentsFilteredByUser.map(equipmentFamily => (
              <>
                <Label className="mt-2">{equipmentFamily.categoryName} - {equipmentFamily.familyName}</Label>
                {equipmentFamily.equipments.map(equipment => (
                  <FormGroup className="ml-1 d-flex align-items-center" check key={equipment.equipmentId}>
                    <>
                      <Input id={equipment.equipmentId}
                        name="wear"
                        className="mt-0"
                        type="checkbox"
                        checked={equipment.enable}
                        onChange={() => handleEquipmentClick(equipment)} />
                      <Label for={equipment.equipmentId} className="mb-0">{equipment.equipmentIdentifier}</Label>
                    </>
                  </FormGroup>
                ))}
              </>
            )) : (
              <T id="noEquipment" />
            )}
          </>
        ) : (
          <Spinner />
        )}
      </CardBody>
    )
  }, [isUserAssigned, hasStatus, firstName, lastName, maintenanceLoading, forceUserAssign, user, userIdAssignedTo, datePlanned, timeRangeStart, timeRangeEnd, durationEstimated, information, addressFull, techcenterBase, maintenance, loading, searchInput, handleSearchInput, equipmentsFilteredByUserLoading, equipmentsFilteredByUser, handleChangePartsOriginId, handleEquipmentClick, handleFilterFreshEquipments, freshEquipment, addressLocationLabel])

  const renderStep2 = React.useCallback(() => {
    return (
      <>
        {!!equipmentItvcodes && !doNotDisplayEquipment && (
          <>
            <CardBody>
              <FormGroup>
                <Label><T id="equipmentNumber" />{`${currentEquipment + 1} / ${equipmentsFiltered.length}`}</Label>
              </FormGroup>
              {!!equipmentsFiltered[currentEquipment].equipmentIdentifier && (
                <FormGroup>
                  <TLabel id="equipmentIdentifier" />{equipmentsFiltered[currentEquipment].equipmentIdentifier}
                </FormGroup>
              )}
              <FormGroup>
                <TLabel id="equipmentCategory" />{equipmentsFiltered[currentEquipment].categoryName}
              </FormGroup>
              <FormGroup>
                <TLabel id="equipmentFamily" />{equipmentsFiltered[currentEquipment].familyName}
              </FormGroup>
              <FormGroup>
                <TLabel id="techComments" />
                <Input type="textarea" name="techComments" id="techComments"
                  value={equipmentItvcodes.techComments || ""}
                  disabled={loading}
                  onChange={e => setEquipmentItvcodes({ ...equipmentItvcodes, techComments: e.target.value })} />
              </FormGroup>
              {itvcodes && itvcodes.length > 0 && itvcodes.map((codes, i) => (
                <React.Fragment  key={codes.familyId}>
                  {codes.familyId === equipmentsFiltered[currentEquipment].familyId && codes.categoryId === equipmentsFiltered[currentEquipment].categoryId  && (
                    <FormGroup>
                      <ItvcodeSelector
                        hasBusiness={Boolean(businessId)}
                        loading={maintenanceLoading || itvcodesLoading}
                        itvcodesAdded={equipmentItvcodes.itvcodes}
                        itvcodes={itvcodesBase && itvcodesBase[i].result}
                        noWear
                        reason={false}
                        onChange={itvcodes => setEquipmentItvcodes((previousEquipmentItvcodes) => ({ ...previousEquipmentItvcodes, itvcodes: itvcodes }))} />
                    </FormGroup>
                  )}
                </React.Fragment>
              ))}
              {availableStatus && availableStatus.length > 0 && <FormGroup tag="fieldset" >
                <TLabel id="statusTo" />
                <CustomInput
                  id="statusTo"
                  className="pt-2"
                  type="select"
                  name="statusTo"
                  value={childStatusIdTo ? `${statusIdTo}.${childStatusIdTo}` : statusIdTo}
                  onChange={e => { handleChangeStatusTo(e.target.value) }}>
                  <Toption value={null} id="statusInit.placeholder" />
                  {availableStatus.map(opt =>
                    <>
                      {opt.children && opt.children.length > 0 ? (
                        <optgroup key={opt.key} label={formatMessage({ id: `equipmentStatus.${opt.key}`, raw: true })}>
                          {opt.children.map(child => (
                            <Toption key={`${opt.key}.${child.key}`}
                              raw id={`equipmentStatus.${opt.key}.${child.key}`}
                              value={`${opt.value}.${child.value}`} />
                          ))}
                        </optgroup>
                      ) : (
                        <Toption key={opt.value}
                          raw id={`equipmentStatus.${opt.key}`}
                          value={`${opt.value}`} />
                      )}
                    </>
                  )}
                </CustomInput>
              </FormGroup>}
              {equipmentChecklist && equipmentChecklist.checklist && equipmentChecklist.checklist.length > 0 && (
                <>
                  <TLabel className="mb-4" id="checklist" />
                  <Button
                    className="ml-4"
                    onClick={handleCheckAllOk}>
                    <T id="allOk" />
                  </Button>
                  {equipmentChecklist.checklist.map(item => (
                    <Card key={item.priority}>
                      <CardBody className='pb-3 pt-3'>
                        <FormGroup tag="fieldset" className="pb-0">
                          <span className="d-block" style={{ fontWeight: 'bold', marginBottom: 8 }}>{item.checkLabel}</span>
                          <div className='d-flex flex-rox mb-2'>
                            {Object.entries(CHECKLIST_STATUS).map(statusItem =>
                              <TCustomInput
                                id={`${item.checkLabel}.${statusItem[1]}`}
                                key={`${item.checkLabel}.${statusItem[1]}`}
                                type="radio"
                                name={`${item.checkLabel}`}
                                label={`check.${statusItem[0]}`}
                                disabled={loading}
                                className="mr-4"
                                onChange={(e) => handleCheckChange({ item, status: parseInt(e.target.value) })}
                                checked={parseInt(item.status) === statusItem[1]}
                                value={parseInt(statusItem[1])} />
                            )}
                          </div>
                          <div>
                            <TLabel id="comments" />
                            <Input
                              id={`${item.checkLabel}.comments`}
                              name="comments"
                              type="text"
                              value={item.comments || ""}
                              onChange={e => { handleCheckChange({ item, comments: e.target.value }) }}
                            />
                          </div>
                        </FormGroup>
                      </CardBody>
                    </Card>
                  ))}
                </>
              )}
            </CardBody>
          </>
        )}
      </>
    )
  }, [CHECKLIST_STATUS, availableStatus, businessId, currentEquipment, doNotDisplayEquipment, equipmentChecklist, equipmentItvcodes, equipmentsFiltered, formatMessage, handleChangeStatusTo, handleCheckAllOk, handleCheckChange, itvcodesBase, itvcodesLoading, loading, maintenanceLoading, statusIdTo, childStatusIdTo, itvcodes])

  const loading = maintenanceLoading || itvcodesLoading || techcenterLoading
  const error = maintenanceError || notAllowedError || techcenterError
  const stepError = finishError || equipmentsError || equipmentItvcodesError || equipmentChecklistError || toChecklistError || nextEquipmentError || itvcodesError || availableStatusError

  const renderButtons = React.useCallback(() => {
    return (
      <>
        {step === 1 && (
          <div className="ml-auto">
            <TButton className="mx-1"
              disabled={loading}
              id="toEquipment"
              onClick={() => { toEquipment() }}
              color="primary" />
          </div>
        )}
        {step === 2 && (
          <div className="ml-auto">
            <TButton className="mx-1"
              disabled={loading}
              id="back"
              onClick={() => { setStep(1) }}
              color="primary" />
            {currentEquipment - 1 >= 0 && (
              <TButton className="mx-1"
                disabled={loading}
                id="toPreviousEquipment"
                onClick={() => { toPreviousEquipment() }}
                color="primary" />
            )}
            {currentEquipment + 1 < equipmentsFiltered.length && (
              <TButton className="mx-1"
                disabled={loading}
                id="toNextEquipment"
                onClick={() => { toNextEquipment() }}
                color="primary" />
            )}
            {currentEquipment + 1 === equipmentsFiltered.length && (
              <TButton className="mx-1"
                disabled={loading}
                id="finish"
                onClick={() => { handleFinish() }}
                color="primary" />
            )}
          </div>
        )}
      </>
    )
  }, [currentEquipment, equipmentsFiltered.length, handleFinish, loading, step, toEquipment, toNextEquipment, toPreviousEquipment])

  const renderError = React.useCallback(() => {
    return (
      <>{stepError && <TAlert id={`error.${stepError.code}`} className="mt-4" color="danger" />}</>
    )
  }, [stepError])

  const hasStatus = React.useCallback((...status) => status.includes(statusValue), [statusValue])

  if (loading) {
    return <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />
  }

  if (error) {
    return (
      <TAlert id={error} color="warning" />
    )
  }

  return (
    <div className="container-fluid MaintenanceStart">
      <NavLink tag={Link} to={`/maintenance/${maintenance.maintenanceplanId}`}>
        <T id="returnToMaintenance" />
      </NavLink>
      {maintenance && (
        <>
          <Card>
            <CardHeader>
              <div className="ml-2">
                <div className="h4">
                  <T id="equipments" />
                  <small className="ml-2">{businessName}</small>
                  <small className="ml-2">{categoryName}</small>
                  <small className="ml-2">{familyName}</small>
                </div>
                <div className="h6 mb-2">
                  <span><T id="maintenanceId" />{` ${maintenanceplanId}`}</span>
                </div>
                {masterplanId && (
                  <div className="h6 mb-0">
                    <span>
                      <T id="masterplanId" />
                      <Link to={`/maintenanceplan/${masterplanId}`}>#{masterplanId}</Link>
                    </span>
                  </div>
                )}
              </div>
            </CardHeader>

            {step === 1 && renderStep1()}
            {step === 2 && renderStep2()}
            <CardFooter>
              <div>
                {renderError()}
              </div>
              <div className='d-flex'>
                {renderButtons()}
              </div>
            </CardFooter>
          </Card>

          < Modal isOpen={changeUserModal} fade={true} toggle={() => setChangeUserModal(!changeUserModal)}>
            <ModalBody>
              <div className="mb-1"><T id="modal.changeUser" /></div>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  setForceUserAssign(true)
                  setChangeUserModal(!changeUserModal)
                  setIsUserAssigned(true)
                }}>
                <T raw id="yes" />
              </Button>
              <Button
                className="ml-2" onClick={() => {
                  setForceUserAssign(false)
                  setChangeUserModal(!changeUserModal)
                }}>
                <T raw id="no" />
              </Button>
            </ModalFooter>
          </Modal >
        </>
      )}
    </div >
  )
}

export default MaintenanceStart
